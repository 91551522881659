import React, { Fragment } from "react";
import { StyledTopInfo, StyledDojoIframe } from "./TopInfo.styled";

import logo from "../../src/assets/logo-wca.png";

const TopInfo = () => {
  return (
    <Fragment>
      <StyledTopInfo>
        <div className="welcome">
          <h1>
            Aikido<span> dojo Pruszków</span>
          </h1>
          <div className="training-plan">
            <h2>L.O. im. T Kościuszki</h2>
            <h2>ul. Kościuszki 38</h2>
            <h3> wtorek czwartek</h3>
            <h3>
              <span>dzieci młodsze:</span> 18.00 - 18.45
            </h3>
            <h3>
              <span>dzieci starsze:</span> 18.50 - 19.35
            </h3>
            <h3>
              <span>dorośli i młodzież:</span> 19.40 - 21.10
            </h3>
          </div>
        </div>
        <a
          className="logo"
          href="https://aikido.warszawa.pl/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={logo} alt="WCA logo" />
        </a>
      </StyledTopInfo>
      <StyledDojoIframe>
        {" "}
        <iframe
          title="Dojo location"
          width="1020"
          height="450"
          frameborder="0"
          src="https://maps.google.com/maps?width=1020&amp;height=450&amp;hl=en&amp;q=Ko%C5%9Bciuszki%2038%20Pruszk%C3%B3w+(Aikido%20Pruszk%C3%B3w)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
      </StyledDojoIframe>
    </Fragment>
  );
};

export default TopInfo;
