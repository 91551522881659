import kids1 from "../assets/dzieci_mlodsze/mlodsze_kolyska.jpg";
import kids2 from "../assets/dzieci_mlodsze/mlodsze_sardynki.jpg";
import kids3 from "../assets/dzieci_mlodsze/mlodsze_motylki.jpg";
import kids4 from "../assets/dzieci_mlodsze/mlodsze_rozgrzewka.jpg";
import kids5 from "../assets/dzieci_mlodsze/mlodsze_ikyo.jpg";
import kids6 from "../assets/dzieci_mlodsze/mlodsze_czolganie.jpg";
import kids7 from "../assets/dzieci_mlodsze/mlodsze_przewrot.jpg";
import kids8 from "../assets/dzieci_mlodsze/mlodsze_szczur.jpg";
import kids9 from "../assets/dzieci_mlodsze/mlodsze_dziewczyny.jpg";
import kids10 from "../assets/dzieci_mlodsze/mlodsze_biegi.jpeg";
import kids11 from "../assets/dzieci_mlodsze/mlodsze_Ola.jpg";
import kids12 from "../assets/dzieci_mlodsze/mlodsze_ksiazka.jpg";

import young1 from "../assets/dzieci_starsze/starsza_ciaganie.jpg";
import young2 from "../assets/dzieci_starsze/starsza_czolganie_mobile.jpg";
import young3 from "../assets/dzieci_starsze/starsza_egzamin.jpg";
import young4 from "../assets/dzieci_starsze/starsza_grupowe_mobile.jpg";
import young5 from "../assets/dzieci_starsze/starsza_palki.jpg";
import young6 from "../assets/dzieci_starsze/starsza_podciecie.jpeg";
import young7 from "../assets/dzieci_starsze/starsza_rzut.jpeg";
import young8 from "../assets/dzieci_starsze/starsza_stanie.jpeg";
import young9 from "../assets/dzieci_starsze/starsza_technika.jpeg";
import young10 from "../assets/dzieci_starsze/starsza_uderzenie.jpeg";
import young11 from "../assets/dzieci_starsze/starsza_zolnierz_mobile.jpg";

import slide1 from "../assets/dorosli/slide1.jpg";
import slide2 from "../assets/dorosli/slide2.JPG";
import slide3 from "../assets/dorosli/slide3.jpg";
import slide4 from "../assets/dorosli/slide4.jpg";
import slide5 from "../assets/dorosli/slide5.jpg";
import slide6 from "../assets/dorosli/slide6.jpg";
import slide7 from "../assets/dorosli/slide7.jpg";
import slide8 from "../assets/dorosli/slide8.jpg";
import slide9 from "../assets/dorosli/slide9.jpg";
import slide10 from "../assets/dorosli/slide10.jpg";
import slide11 from "../assets/dorosli/slide11.jpg";
import slide12 from "../assets/dorosli/slide12.jpg";
import slide13 from "../assets/dorosli/slide13.JPG";
import slide14 from "../assets/dorosli/slide14.JPG";
import slide15 from "../assets/dorosli/slide15.jpg";




export const Kids = [
  kids1,
  kids2,
  kids3,
  kids4,
  kids5,
  kids6,
  kids7,
  kids8,
  kids9,
  kids10,
  kids11,
  kids12,
];
export const Young = [
  young1,
  young2,
  young3,
  young4,
  young5,
  young6,
  young7,
  young8,
  young9,
  young10,
  young11,
];

export const Adults = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  slide10,
  slide11,
  slide12,
  slide13,
  slide14,
  slide15,

];

